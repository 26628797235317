/**
 * Validate level codes for SMC, YFS, SM127, and SMF1/2/3.
 * This function checks the passed code if they work with all games.
 * If the code is e.g. editing a SMC level, but passes a YFS level code,
 * it will notify them.
 *
 * @param {*} levelCode
 * @param {*} optionalGameId
 * @param {*} optionalGameVersion
 * @returns { validationStatus object }
 */
export const validateLevelCode = (
	levelCode,
	optionalGameId,
	optionalGameVersion,
	optionalDescription
) => {
	// state for description

	let validationStatus = {
		isValid: false,
		game: -1,
		gameVersion: "", // Version of the game that generated the level code
		name: "",
		gameStyle: "",
		gameVer: "",
		description: optionalDescription, // Not every level has a description, and SM127 levels in particular have no support for this.
		error: "The level code could not be recognized. Check if you've copied the full thing.", // Default error
	};

	// Remove empty characters (spaces) at the start and end of the level code
	levelCode = levelCode?.trim();

	// If no level code has been entered at all, just cancel the checks immediately
	if (!levelCode) {
		validationStatus.error = "Enter a level code to continue.";
		return validationStatus;
	}

	// First check: check if the level code submitted is a JSON string. SMC and YFS level codes are stored in JSON format.
	try {
		levelCode = JSON.parse(levelCode);

		// CHECK IF LEVEL CODE JSON HAS KEY //
		// This function will accept an array of keys to check for existing. If all keys exist, it will return true.
		function keyExists(keys) {
			let allKeysExist = true;

			keys?.forEach((key) => {
				if (!levelCode.hasOwnProperty(key)) {
					allKeysExist = false;
				}
			});
			return allKeysExist;
		}

		// If this is true, the level code submitted can be assumed for either SMC or YFS, since their level codes are JSON objects.
		if (levelCode && typeof levelCode === "object") {
			/* --------------------- */
			/* SUPER MARIO CONSTRUCT */
			/*   LEVEL CODE CHECKS   */
			/* --------------------- */
			if (keyExists(["ver", "style", "name", "start", "maps"])) {
				validationStatus = {
					...validationStatus,
					isValid: true,
					game: 0, // SMC ID
					gameVersion: levelCode.ver,
					name: levelCode.name,
					gameStyle: levelCode.style, // Exclusive property to SMC levels
				};

				// Not every SMC level has a description, so only update this property if it exists
				if (keyExists(["desc"]) && !optionalDescription) {
					validationStatus.description = levelCode.desc;
				}

				/* --------------------------- */
				/* YOSHI'S FABRICATION STATION */
				/*      LEVEL CODE CHECKS      */
				/* --------------------------- */
			} else if (keyExists(["version", "name", "startpoint"])) {
				validationStatus = {
					...validationStatus,
					isValid: true,
					game: 1, // YFS ID
					gameVersion: levelCode.version,
					name: levelCode.name,
				};

				// Not every YFS level has a description, so only update this property if it exists
				if (keyExists(["desc"]) && !optionalDescription) {
					validationStatus.description = levelCode.desc;
				}

				/* --------------------------- */
				/*   SMC V7 (LEGACY CODEBASE)  */
				/*      LEVEL CODE CHECKS      */
				/* --------------------------- */
			} else if (keyExists(["newdata", "size", "data"])) {
				validationStatus = {
					...validationStatus,
					isValid: true,
					game: 0, // SMC ID
					gameVersion: "v7",
				};
			}
		}

		// When not a JSON object, level codes are typically 2D arrays in some sort (separated by commas), which could be a SM127 or legacy SMF level code.
	} catch {
		const trimmedCode = levelCode.split(",");

		/* ----------------- */
		/*  SUPER MARIO 127  */
		/* LEVEL CODE CHECKS */
		/* ----------------- */

		// In SM127 level codes, the game version is stored in the first index
		const gameVer = trimmedCode[0];

		// check different level code version formats
		const sm127CodeLegacy = trimmedCode?.[2]?.startsWith("[") &&
			trimmedCode?.[2]?.endsWith("]") &&
			trimmedCode?.[3]?.startsWith("[") &&
			trimmedCode?.[trimmedCode.length - 1]?.endsWith("]")
		const sm127Code050 =
			trimmedCode?.[5]?.startsWith("[") &&
			trimmedCode?.[trimmedCode.length - 1]?.endsWith("]")

		// First, check if the version number only includes numbers and dots - then check if the third index has an empty array[], and finally, check the 4th index to start with an [, and the last one to end with an], as these 2 brackets contain the level's contents.
		if (
			/^[0-9.]*$/.test(gameVer) &&
			(sm127CodeLegacy || sm127Code050)
		) {
			validationStatus = {
				...validationStatus,
				isValid: true,
				game: 2, // 127 ID
				gameVersion: gameVer,
				description: decodeURIComponent(trimmedCode?.[3]) || validationStatus?.description,
				name: decodeURIComponent(trimmedCode[1]),
			};
		}

		/* -------------------------- */
		/* SUPER MARIO FLASH 1, 2 & 3 */
		/*      LEVEL CODE CHECKS     */
		/* -------------------------- */

		// For SMF1, there isn't much to check, since it's just patches of (data)(more data).
		// At least a main area & bonus zone are in, which means 2 brackets, so check for ',)(' as well (there's always a comma before the closing bracket).
		if (
			levelCode.startsWith("(") &&
			levelCode.endsWith(",)") &&
			levelCode.includes(",)(")
		) {
			validationStatus = {
				...validationStatus,
				isValid: true,
				game: 3, // SMF games ID
				gameVer: "SMF1",
			};
		}

		// SMF2 level code checks, this game separates series of values with the '&' character.
		// The quirk of there being one extra comma than necessary remains from SMF1 as well, the code ends with , followed by a &.
		// Note: SMF3 is a heavily modded version of SMF2, but still contains the same level code structure, so is detected as SMF2.
		// (An exception is for SMF2 Ver.C, which is detected by below code, due the maps system increasing '&' character count).
		const dataSeparatorCount = levelCode.split("&").length - 1;

		if (
			levelCode.startsWith("&") &&
			levelCode.endsWith(",&") &&
			dataSeparatorCount >= 10 // There has to be at least 9 occurrences of the & character in the level code
		) {
			validationStatus = {
				...validationStatus,
				isValid: true,
				game: 3, // SMF games ID
				gameVer: dataSeparatorCount <= 12 ? "SMF2" : "VerC",
			};
		}
	}

	/* ---------------------- */
	/*      FINAL CHECKS      */
	/* ---------------------- */

	// ! Checking for errors in level code
	// The below code blocks make some final checks to make sure the level code is valid (and if the code can be replaced with an existing code).
	// * If none of the code blocks trigger, then isValid will simply stay on true.

	// CHECKING FOR BETAS/SNAPSHOTS: Level Share Square doesn't generally allow levels in snapshot builds if the game is already out of beta.
	// For SMC and YFS, we can check this in the level code version property.
	if (
		validationStatus.game === 1 &&
		!validationStatus.gameVersion.startsWith("s")
	) {
		validationStatus.isValid = false;
		validationStatus.error =
			"Sorry, levels made in snapshot releases are not allowed, since they contain experimental features.";
	}

	// ! Deprecated SMC V7 level code checks
	// Uploading new SMC V7 (legacy codebase) levels are not supported, encourage people to use V8 instead. SMC V7 levels can still be edited.
	// Note the undefined check below is because gameIdToCheck for SMC is 0, which JavaScript sees as a false, so we just check for undefined instead (this means they're on the add level page, not edit.)
	if (
		validationStatus.game === 0 &&
		validationStatus.gameVersion === "v7" &&
		optionalGameVersion !== "v7"
	) {
		validationStatus.isValid = false;
		validationStatus.error =
			"Sorry, uploading new Super Mario Construct levels made in Version 7 or below is not supported. You can still edit your old ones, but new levels have to use Version 8.";
	}

	// ! Disallow SMF level codes
	// When uploading new levels (gameIdToCheck is undefined), don't allow Super Mario Flash levels to be uploaded, since that game is considered in an archived state.
	if (!optionalGameId && validationStatus.game === 3) {
		validationStatus.isValid = false;
		validationStatus.error =
			"Sorry, uploading new Super Mario Flash levels is not supported. You can still edit your old ones.";
	}

	if (validationStatus.isValid) validationStatus.error = "";

	return validationStatus;
};
