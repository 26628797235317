import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../App";
import React from "react";
import { PatternDefaultSizes } from "../constants";

declare global {
	interface Window {
		YT: any; // Adjust the type if you have more specific information about the YT property
	}
}

const ThemeRenderer = ({
	setMusicActive,
	musicActive,
	videoId,
	setVideoId,
}) => {
	// states
	const [currentTheme, setCurrentTheme] = useState<string[]>([
		"squarehue",
		"default",
		"top left",
	]);
	const [previousTheme, setPreviousTheme] = useState<string>("");
	const [themeApplier, setThemeApplier] = useState<boolean>(false);
	const [currentLocation, setCurrentLocation] = useState<any>(
		window.location.pathname,
	);
	const [navChanged, setNavChanged] = useState<boolean>(true);
	const [previouslyMusicActive, setPreviouslyMusicActive] =
		useState<boolean>(false);

	// toggle on off music
	const handleMusicToggle = () => {
		setMusicActive((prevState) => !prevState);
	};

	// theme class loaders
	const bodyElement = document.querySelector("body");
	const bodyTheme: DOMTokenList | null = bodyElement
		? bodyElement.classList
		: null;

	const history = useHistory();

	// define as constant
	const currentUserData = useContext<any>(UserContext);

	// control variables
	const existingThemes: string[] = [
		"squarehue",
		"memoryfibre",
		"inkletgloss",
		"gloamvitiation",
		"butterflybrew",
		"wildheart",
		"hydrawisp",
		"riftshade",
		"moonash",
		"sugarblur",
		"swirlberry",
		"cloudwave",
		"asteroidcrunch",
		"trickytreat",
		"aquatune",
		"default",
		"strong",
		"stripes",
		"cosmic",
		"berry",
		"spooky",
		"fishy",
		"pipeline",
	];
	const themeExists = existingThemes.some((theme) =>
		currentUserData?.selected_themes?.includes(theme),
	);

	const [hasActivatedMusic, setHasActivatedMusic] = useState<boolean>(false);
	// initial music
	useEffect(() => {
		if (hasActivatedMusic) return;
		if (currentUserData?.music_ids) {
			setHasActivatedMusic(true);
			setVideoId(currentUserData?.music_ids?.[0]);
		}
	}, [
		setHasActivatedMusic,
		setVideoId,
		hasActivatedMusic,
		currentUserData?.music_ids,
	]);

	useEffect(() => {
		return history.listen((location) => {
			const pathname = location.pathname;
			// listen to the history and update location
			if (pathname !== currentLocation && navChanged === false) {
				setNavChanged(true);
				setCurrentLocation(pathname);
				// reenable music player when leaving game page
				if (
					!pathname.includes("/games/supermarioconstruct") &&
					!pathname.includes("/games/yoshisfabricationstation") &&
					previouslyMusicActive === true
				) {
					// disable music on game pages
					setPreviouslyMusicActive(false);
					return setMusicActive(true);
				}
			}
			// music handler

			if (
				["on-limited", "on"].includes(
					currentUserData?.general_settings[1],
				)
			) {
				if (!pathname.includes("/users/")) {
					if (
						pathname.includes("/games/supermarioconstruct") ||
						pathname.includes("/games/yoshisfabricationstation")
					) {
						// disable music on game pages
						if (musicActive) setPreviouslyMusicActive(true);
						return setMusicActive(false);
					}
					// define music url of user
					const musicUrl =
						currentUserData?.music_ids?.[0] || "ju1NDfacnQ8";
					// switch music back to default when leaving profiles
					if (videoId !== musicUrl) return setVideoId(musicUrl);
					return;
				}
			}
		});
	}, [
		history,
		navChanged,
		currentLocation,
		currentUserData?.general_settings,
		previouslyMusicActive,
		setMusicActive,
		currentUserData?.music_ids,
		setVideoId,
		videoId,
		currentUserData?._id,
		setHasActivatedMusic,
		musicActive,
	]);

	useEffect(() => {
		const selectedThemes = currentUserData?.selected_themes;
		// make sure there is an user and an existing theme
		if (
			(selectedThemes?.[0] !== bodyTheme?.[0] ||
				selectedThemes?.[1] !== bodyTheme?.[1] ||
				selectedThemes?.[2] !== bodyTheme?.[2] ||
				selectedThemes?.[3] !== bodyTheme?.[3]) &&
			selectedThemes?.length &&
			themeExists &&
			currentUserData?._id &&
			navChanged
		) {
			// update state to trigger second useEffect call
			setCurrentTheme(selectedThemes);
			return setThemeApplier(true);
		}

		if (
			(!currentUserData?._id ||
				!currentUserData?.selected_themes?.length) &&
			navChanged
		) {
			// default theme uses default state for currentTheme
			return setThemeApplier(true);
		}
		// fallback
		return setNavChanged(false);
	}, [currentUserData, navChanged, bodyTheme, themeExists]);

	useEffect(() => {
		//! run theme selector every time the page changes
		if (themeApplier === true) {
			const colorTheme = currentTheme?.[0];
			const pattern = currentTheme?.[1];
			if (window.location.pathname !== "/users" && bodyTheme) {
				// create a loop for the bodytheme length
				for (
					let i = bodyTheme !== null ? bodyTheme?.length - 1 : 0;
					i >= 0;
					i--
				) {
					// remove any themes that arent present
					if (
						bodyTheme[i] !== colorTheme &&
						bodyTheme[i] !== pattern
					) {
						bodyTheme?.remove(bodyTheme[i]);
					} else if (i < 0) break;
				}
				// apply the theme
				bodyTheme?.add(colorTheme);
				bodyTheme?.add(pattern);
				// define the size to use
				let sizeToUse = currentTheme?.[3];
				const backgroundPosition = currentTheme?.[2] || "top left";

				if (!currentUserData?._id || !sizeToUse)
					sizeToUse =
						PatternDefaultSizes.find((pat) => pat.value === pattern)
							?.size || "65";
				// now apply background position/size
				const root: any = document.querySelector(":root");
				const rootStyle: any = root?.style;
				rootStyle.setProperty("--bg-size", `${sizeToUse}vw`);
				rootStyle.setProperty("--bg-position", backgroundPosition);
				// adjust theme colour for mobile/web-app
				themeColorSwitcher(colorTheme); // enable transition animations after applying the styles
				const themeString = `${colorTheme}-${pattern}-${backgroundPosition}-${sizeToUse}`;
				const bodyStyle: any = document.body.style;
				if (
					previousTheme === themeString &&
					!document.body.classList.contains("transition-enabled")
				) {
					bodyStyle.transition = `
						background-position 1.5s linear,
						background-size 1.5s linear`;
					bodyStyle.transitionDelay = "0.5s";
				}
				setPreviousTheme(themeString);
			}
			setThemeApplier(false);
		}
		// reset
		setNavChanged(false);
	}, [
		themeApplier,
		bodyTheme,
		currentTheme,
		currentUserData?._id,
		previousTheme,
	]);

	return currentUserData &&
		["on-limited", "on"].includes(currentUserData.general_settings[1]) ? (
		// music changer button
		<span className="d-none d-lg-block">
			<button
				className="global-music-player"
				onClick={() => handleMusicToggle()}>
				<span
					className="material-icons"
					style={{
						position: "relative",
						top: "2px",
						fontSize: "32px",
					}}>
					{musicActive ? "volume_up" : "volume_off"}
				</span>
			</button>
			<iframe
				style={{
					position: "fixed",
					visibility: "hidden",
					right: "-50px",
				}}
				title="Music player"
				width="1"
				height="1"
				src={`https://www.youtube.com/embed/${videoId}?autoplay=${
					musicActive ? 1 : 0
				}&loop=1&playlist=${videoId}`}
				allowFullScreen={false}
			/>
		</span>
	) : null;
};

// primary tool for setting the current theme
export const themeColorSwitcher = (theme) => {
	let themeColor = "#10127c";

	switch (theme[0]) {
		case "squarehue":
			themeColor = "#10127c";
			break;
		case "memoryfibre":
			themeColor = "#7116cd";
			break;
		case "inkletgloss":
			themeColor = "#00113e";
			break;
		case "gloamvitiation":
			themeColor = "#cf4d02";
			break;
		case "butterflybrew":
			themeColor = "#8a23ba";
			break;
		case "wildheart":
			themeColor = "#01522c";
			break;
		case "hydrawisp":
			themeColor = "#3d0101";
			break;
		case "riftshade":
			themeColor = "#171717";
			break;
		case "moonash":
			themeColor = "#a6a692";
			break;
		case "sugarblur":
			themeColor = "#fc83c1";
			break;
		default:
			themeColor = "#10127c";
			break;
	}
	document
		.getElementById("theme-color-meta")
		?.setAttribute("content", themeColor);
};

export default ThemeRenderer;
