import React, { useContext } from "react";
import { UserContext } from "../../App";

const EventPoll = ({ event, setShowSubmitModal }) => {
	const active = event?.active;
	const currentUserData = useContext(UserContext);
	const pollOptionsAndVotes:
		| [
				{
					value: string;
					description: string;
					votes: string[];
					image: string;
				},
		  ]
		| [] = event?.pollOptions;

	const totalVoteCount = event?.pollVotes || 0;
	const startDate = new Date(event?.startDate);
	const oneHourAfterStart = new Date(startDate.getTime() + 3600 * 1000);
	const afterStartDate = oneHourAfterStart <= new Date();

	if (pollOptionsAndVotes?.length > 0)
		return (
			<>
				<h1 className="center-textoutput">
					{event?.pollTitle || "Polling"}
				</h1>
				{pollOptionsAndVotes?.map((option) => {
					const votePercentage = option?.percentage || 0;
					const selected = option?.votes?.includes(
						currentUserData?._id,
					);
					const winner = !active && option?.winner === true;

					return (
						<div
							key={option?.value}
							className="event-poll"
							style={{
								boxShadow: selected
									? "rgb(240, 208, 0) 0px 0px 3px 2px"
									: "white 0px 0px 3px 2px",
							}}>
							<div className="event-poll__barbox">
								<div
									className="event-poll__fillbar"
									style={{
										width: `${votePercentage}%`,
									}}></div>{" "}
								<div
									className={`event-poll__border-${selected ? "gold" : "reg"}`}></div>
							</div>
							<div
								className="event-poll__text"
								style={
									selected && active
										? {
												backgroundColor:
													"rgb(240, 208, 0, 0.3)",
											}
										: winner
											? {
													backgroundColor:
														"rgb(55, 255, 0, 0.2)",
												}
											: {}
								}>
								<span
									style={
										winner
											? { color: "rgb(55, 255, 0)" }
											: {}
									}>
									{option?.value}
								</span>
								<span
									className="yellow"
									style={
										winner
											? { color: "rgb(55, 255, 0)" }
											: {}
									}>
									{votePercentage}%
								</span>
							</div>
						</div>
					);
				})}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignContent: "center",
					}}>
					<button
						className="btn btn-primary btn-block d-none d-lg-block"
						disabled={!active || currentUserData?.rank < 5}
						onClick={() => setShowSubmitModal(true)}
						style={{ whiteSpace: "nowrap" }}>
						{currentUserData?.rank < 5
							? "Unlocks at rank 5"
							: active
								? "Vote on poll"
								: !afterStartDate
									? "Poll hasn't opened yet."
									: "Voting closed"}
					</button>
					<div style={{ textAlign: "right", width: "100%" }}>
						{totalVoteCount} total votes
					</div>
				</div>
			</>
		);

	return (
		<div className="center-textoutput gray">
			<i>Your poll doesn't have any values yet to vote for!</i>
		</div>
	);
};

export default EventPoll;
