import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import "./style.scss";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { UserContext } from "../../App";
import { removeLevelThumbnail } from "../../api";

export const LevelFileUpload = (props) => {
	// define an ondrop attribute
	const onDrop = (acceptedFiles) => {
		const file = acceptedFiles[0];

		// check if an mb64 file is an mb64 file
		if (!file || !file.name?.toLowerCase()?.endsWith(".mb64")) {
			return toast.error("Invalid file! Please upload a .mb64 file.");
		}

		// update state for confirmation
		const fileNameParts = file?.name?.split(".");
		const levelName = fileNameParts
			?.slice(0, fileNameParts.length - 1)
			.join(".");
		// update the level
		props.setLevel((prev) => ({
			...prev,
			game: 4,
			...(props?.type === "event"
				? { content: file, binaryContent: true }
				: { file: file }),
			name: prev?.name ? prev?.name : levelName,
		}));
		// update state of uploadability
		props.setLevelFileStatus(true);

		// Only update this state for events
		if (props?.type !== "event")
			// update game
			props.setLevelGameId(4);
	};

	// use a dropzone
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"content/mb64": [".mb64"], // Add MIME type for .mb64 if needed
		},
	});

	return (
		<span>
			<div className="thumbnail-upload" {...getRootProps()}>
				<input type="file" {...getInputProps()} accept=".mb64" />
				{(props?.level?.file !== null ||
					(props?.level?.content && props?.level?.binaryContent)) &&
				props?.level?.filename !== "" &&
				isDragActive ? (
					<p>Drop the file here...</p>
				) : (
					<p>Drag or drop your file.</p>
				)}
				{props?.level?.game === 4 && (
					<div>
						<u>
							Uploaded{" "}
							<span className="blue">
								{props?.level?.file?.name ||
									props?.level?.content?.name}
							</span>{" "}
							successfully!
						</u>
					</div>
				)}
			</div>
		</span>
	);
};

export const ThumbnailUpload = (props) => {
	const currentUserData = useContext<any>(UserContext);
	const [modalShow, setModalShow] = useState<boolean>(false);
	const [clearState, setClearState] = useState<boolean>(false);
	const [thumbnail, setThumbnail] = useState<any>(props.level?.thumbnail);
	const [thumbnailURL, setThumbnailURL] = useState<any>(
		props?.level?.thumbnail,
	);

	const onDrop = (acceptedFiles) => {
		const file = acceptedFiles[0];
		setThumbnail(file);
		props.setLevel({
			...props.level,
			thumbnail: file,
		});
	};

	// clear the thumbnail field
	const handleClearField = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// require second click
		if (clearState === false) {
			toast.info("Click again to clear this field.");
			setTimeout(() => setClearState(false), 4000);
			return setClearState(true);
		}
		// update
		setThumbnail("");
		props.setLevel({
			...props.level,
			thumbnail: "",
		});
	};

	// handle the deletion
	const handleThumbnailDeletion = () => {
		toast.info("Deleting thumbnail...");
		setModalShow(false);
		removeLevelThumbnail(props?.level?._id).then(() => {
			setThumbnail("");
			props.setHasThumbnail(false);
			props.setLevel({
				...props.level,
				thumbnail: "",
			});
		});
	};

	// display modal for deleting profile comment
	const thumbnailDeletionModal = () => {
		const owner =
			props?.level?.author?._id === currentUserData?._id ? "your" : "the";

		return (
			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="sm"
				className="popup-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<span className="modal-fill">
					<Modal.Body className="modal-filter">
						{modalShow ? (
							<h4 className="center-text">
								This will{" "}
								<span className="yellow">
									permanently remove
								</span>{" "}
								{owner} thumbnail image. .{" "}
								{owner === "your"
									? "You can always add a new thumbnail."
									: ""}
							</h4>
						) : (
							<h3 className="center-text">Closing...</h3>
						)}
					</Modal.Body>
					<Modal.Footer className="mx-auto modal-buttons modal-filter">
						<Button
							className="btn btn-primary__danger"
							onClick={() => handleThumbnailDeletion()}>
							Delete thumbnail
						</Button>
						<Button onClick={() => setModalShow(false)}>
							Cancel
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".jpeg", ".jpg"],
		},
		multiple: false,
	});

	return (
		<span>
			<select
				className="access-form"
				defaultValue={props.thumbLinkMode}
				onChange={(e) => {
					// update the upload type
					props.setThumbLinkMode(e.target.value);
					props.setLevel({
						...props.level,
						thumbnail:
							e.target.value === "upload"
								? thumbnail
								: thumbnailURL,
					});
				}}>
				<option value="upload">Upload a file</option>
				<option value="link">Link to image URL</option>
			</select>
			{props.hasThumbnail === true ? (
				<OverlayTrigger
					overlay={
						<Tooltip id="deletethumb">Delete thumbnail</Tooltip>
					}>
					<button
						className="btn btn-primary__danger"
						style={{
							width: "32px",
							height: "32px",
							marginLeft: "8px",
						}}
						onClick={(e) => {
							e.preventDefault();
							setModalShow(true);
						}}>
						<span
							className="material-icons"
							style={{
								position: "relative",
								right: "7px",
								bottom: "2px",
							}}>
							delete
						</span>
					</button>
				</OverlayTrigger>
			) : null}
			<br />
			<br />
			{/* display the input fields */}
			{props.thumbLinkMode === "upload" ? (
				<div className="thumbnail-upload" {...getRootProps()}>
					<span className="yellow">
						Image hosting powered by{" "}
						<a
							href="https://imgur.com/upload"
							target="_blank"
							rel="noreferrer noopener"
							onClick={(e) => e.stopPropagation()}>
							imgur
						</a>
					</span>
					<hr />
					<input
						type="file"
						{...getInputProps()}
						accept="image/png, image/jpeg, image/jpg"
					/>
					{!thumbnail && isDragActive ? (
						<p>Drop the thumbnail image here...</p>
					) : (
						<p>Drag or click to upload your file here!</p>
					)}
					{thumbnail && (
						<div>
							<p>
								<button
									className={`btn btn-primary${
										clearState ? "__danger" : ""
									}`}
									onClick={(e) => handleClearField(e)}>
									Clear upload field
								</button>
							</p>
							<img
								src={
									thumbnail instanceof Blob
										? URL.createObjectURL(thumbnail)
										: thumbnail
								}
								alt="Thumbnail"
								className="uploaded-image"
							/>
						</div>
					)}
				</div>
			) : (
				<div className="form-group">
					{/* thumbnail url */}
					<div>
						Try out{" "}
						<a
							href="https://imgur.com/upload"
							target="_blank"
							rel="noreferrer noopener">
							imgur
						</a>
						, right click your uploads to copy their URL.
					</div>
					<br />
					<input
						type="text"
						className="form-control"
						id="thumbnailURL"
						maxLength={100}
						value={props.level?.thumbnail}
						onChange={(e) => {
							// store the value
							setThumbnailURL(e.target.value);
							// update state of level
							props.setLevel((prevState) => {
								return {
									// keep previous state, update thumbnail
									...prevState,
									thumbnail: e.target.value,
								};
							});
						}}
					/>
					{thumbnailURL && (
						<div>
							<p>
								<u>Currently selected thumbnail</u>
							</p>
							<img
								src={thumbnailURL}
								alt="Thumbnail"
								className="uploaded-image"
							/>
						</div>
					)}
				</div>
			)}
			{thumbnailDeletionModal()}
		</span>
	);
};
