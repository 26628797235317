import { api } from "./index";

//!--------------------------//
//! --- event api routes --- //
//!--------------------------//

// --- events --- //
export const getEvents = (page) => api.get("/events/get?page=" + page);
export const getEvent = (id) => api.get("/events/" + id + "/get");
export const concludeEvent = (id) => api.patch("/events/" + id + "/conclude");

// --- submissions --- //
export const getSubmissionContent = (id) =>
	api.get(`/events/submissions/${id}/content`);
export const getEventSubmissions = (event, eventContent) => {
	const params = new URLSearchParams(eventContent).toString();
	return api.get("/events/" + event + "/submissions/get?" + params);
}
export const createEventSubmission = (formData, eventId) =>
	api.post(`/events/${eventId}/submit`, formData);

export const gradeEventSubmission = (submissionId, review) =>
	api.patch(`/events/submissions/${submissionId}/grade`, review);

// --- polls --- //
export const voteEventPoll = (eventId, vote) => api.patch(`/events/${eventId}/vote`, vote);
