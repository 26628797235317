import React from "react";

const StarEmpty = ({ width, height }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 153.77994 147.24228"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="layer1" transform="translate(-28.110031,-74.878864)">
			<path
				d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
				style={{
					opacity: 1,
					fill: "#241c1c",
					stroke: "#ffffff",
					strokeWidth: 6,
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeMiterlimit: 20,
					strokeDasharray: "none",
					strokeDashoffset: 0,
					strokeOpacity: 1,
					paintOrder: "normal",
				}}
				transform="matrix(1,0,0,-1,47.716213,251.21368)"
			/>
		</g>
	</svg>
);

export default StarEmpty;
