import React, { useContext, useEffect, useState } from "react";

// page styles
import "./style.scss";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { UserContext } from "../../App";
import { Spinner } from "react-bootstrap";

/**
 * The games page of the site, listing all the games you can use
 * to make levels.
 *
 * @returns The site's games page.
 */
export const Games = (props) => {
	const games = props?.gameProperties;

	useEffect(() => {
		document.title = "Games - Level Share Square";
	}, []);

	return (
		<div className="container mt-4">
			<div className="jumbotron gamepage-top">
				<h2
					className="display-4"
					style={{ textAlign: "center", width: "100%" }}>
					Select a game!
				</h2>
			</div>

			<div
				className="jumbotron"
				style={{
					borderTopRightRadius: "0",
					borderTopLeftRadius: "0",
					backgroundColor: "rgba(80, 17, 142, 0.2)",
				}}>
				{games?.length > 0 ? (
					// map the games
					<div className="game-list">
						{games?.map((game: any) => (
							<React.Fragment key={game?._id}>
								<Game {...{ ...props, game }} />
							</React.Fragment>
						))}
					</div>
				) : (
					// loading
					<div className="d-flex justify-content-center">
						<Spinner animation="grow" variant="primary" />
					</div>
				)}
			</div>
		</div>
	);
};

export const Game = (props) => {
	const currentUserData = useContext<any>(UserContext);
	const [transitioned, setTransitioned] = useState<boolean>(true);
	const history = useHistory();
	const game = props?.game;

	// update the transitioned state and selected game if applicable
	const updateTransition = (input) => {
		setTransitioned(input);
		if (props?.setSelectedGame) props.setSelectedGame(input ? game : null);
	};

	return (
		<div
			className="game-list__button game-list__transition"
			onMouseEnter={() => updateTransition(true)}
			onMouseLeave={() => updateTransition(false)}
			onTransitionEndCapture={() => setTransitioned(true)}
			style={{
				backgroundImage: `url(${game?.banner})`,
				...(transitioned ? {} : { zIndex: "5" }),
			}}
			onClick={() => {
				//! go to the level select corresponding to the game
				if (props.setGameParam) {
					// go to the level list
					history.push(`/${game?.acronym}/levels`);
					// if theres an user session, update default filter
					if (props?.setCurrentUserData && currentUserData?._id)
						props.setCurrentUserData((prevState) => {
							let newSettings = prevState?.general_settings || [];
							newSettings[0] = game?.internalID;
							return {
								...prevState,
								general_settings: newSettings,
							};
						});
					// update the game param
					return props.setGameParam(game?.internalID);
				}
				//! go to the game page, either on site or third party
				if (game?.url) {
					if (
						!game?.url.includes("https") &&
						!game?.url.includes("/html5/")
					)
						// on site
						return history.push(game?.url);
					// third part
					return (window.location.href = game?.url);
				}
				toast.warn("This game isn't available yet, check back later!");
			}}>
			{game?.forceTail ? (
				// archive
				<div
					className="material-symbols-outlined game-list__symbol"
					title="This game is only available for archiving purposes."
					style={{ backgroundColor: "rgba(0, 122, 222, 0.88)" }}>
					inventory_2
				</div>
			) : game?.priority ? (
				// priority
				<div
					className="material-symbols-outlined game-list__symbol"
					title="This game is currently featured on the site!"
					style={{ backgroundColor: "rgba(18, 180, 0, 0.88)" }}>
					kid_star
				</div>
			) : null}
			<h3 className="game-list__title">{game?.name}</h3>
		</div>
	);
};
