import { api } from "./index";

//!--------------------------//
//! --- level api routes --- //
//!--------------------------//

// levels
export const getLevelsList = (levelsData) => {
	const params = new URLSearchParams(levelsData).toString();
	return api.get(`/levels/filter/get?${params}`);
};
export const getSharedLevelsPage = (levelsData) => {
	const searchQuery = new URLSearchParams(levelsData).toString();
	return api.get(
		`/levels/shared/get?${searchQuery}`
	);
}
export const getFeaturedLevelsPage = (levelsData) => {
	const params = new URLSearchParams(levelsData).toString();
	return api.get(`/levels/featured/get?${params}`);
};
export const getFavouriteLevels: any = (levelsData) => {
	const requestUrl =
		`/levels/favourites/${levelsData?.id}/get` +
		`?page=${levelsData?.page}&game=${levelsData?.game}&sort=${levelsData?.sort}` +
		`&keep=${levelsData?.keep}&count=${levelsData?.count}&authors=${levelsData?.authors}`;
	return api.get(requestUrl);
};
export const getLevel = (levelData) =>
	api.get(
		`/levels/${levelData.id}?random=${levelData.random}&game=${levelData.game}&userID=${levelData.userID}&sample=${levelData.sample}&keep=${levelData.keep}`
	);
export const updateLevelVideo = (levelData) =>
	api.patch(`/levels/${levelData.id}/video`, levelData);

// create a level and a thumbnail
export const createLevel: any = (formData, thumbnailIsFile, imageData) => {
	const levelValue = formData.get("level");
	const parsedLevel = JSON.parse(levelValue);
	if (thumbnailIsFile) {
		return api
			.post(`/accesspoint/image/upload/null`, imageData)
			.then((response) => {
				parsedLevel.thumbnail = response?.data?.image;
				parsedLevel.deletehash = response?.data?.deletehash;
				formData.set("level", JSON.stringify(parsedLevel));
			})
			.catch(() => { })
			.then(() => {
				return api.post("/levels", formData);
			});
	} else return api.post("/levels", formData);
};

// update a level and its thumbnail
export const updateLevel = (formData, thumbnailIsFile, imageData) => {
	const levelValue = formData.get("level");
	const parsedLevel = JSON.parse(levelValue);

	// upload the thumbnail if applicable
	if (thumbnailIsFile) {
		return api
			.post(`/accesspoint/image/upload/${parsedLevel._id}`, imageData)
			.then((response) => {
				parsedLevel.thumbnail = response?.data?.image;
				parsedLevel.deletehash = response?.data?.deletehash;
				formData.set("level", JSON.stringify(parsedLevel));
			})
			.catch(() => { })
			.then(() => {
				// update the level after image upload
				return api.patch(
					`/levels/${parsedLevel._id}/update/?type=level`,
					formData
				);
			});
	} else {
		// If thumbnail is not being uploaded, directly patch the level
		return api.patch(
			`/levels/${parsedLevel._id}/update/?type=level`,
			formData
		);
	}
};
export const removeLevelThumbnail = (levelID) =>
	api.patch(`/levels/${levelID}/remove_thumbnail/?type=level`);

//! access data forces out 2 different routers to change middleware
export const manageLevelAccess = (id, accessData) =>
	api.patch(`/levels/${id}/manage/${accessData.mode}?type=level`, accessData);

export const deleteLevel = (id) => api.patch(`/levels/${id}/delete?type=level`);
export const restoreLevel = (id) =>
	api.patch(`/levels/${id}/restore?type=level`);
export const unfeatureLevel = (id) =>
	api.patch(`/levels/${id}/unfeature?type=level`);
export const featureLevel = (id, featureData) =>
	api.patch(`/levels/${id}/feature?type=level`, featureData);
export const favouriteLevel = (id) => api.patch(`/levels/${id}/favourite`);
export const obliterateLevel = (id) => api.delete(`/levels/${id}`);
export const addToPlayCount = (levelID) => api.patch(`/levels/${levelID}/play`);
export const addToPlayCountAsGuest = (levelID) =>
	api.patch(`/levels/${levelID}/play_guest`);
export const rateLevel = (id, rateData) =>
	api.patch(`/levels/${id}/rate`, rateData);
export const removeLevelRate = (contentID, rateData) =>
	api.patch(
		`/levels/${contentID}/rate/${rateData.user}/remove?index=${rateData.index}&type=level_rate`
	);