import React from "react";

const StarHalf = ({ width, height, color }) =>
	// render red
	color === "#c70700" ? (
		<svg
			width={width}
			height={height}
			viewBox="0 0 153.77994 147.24228"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
				<linearGradient id="linearGradient5453">
					<stop
						style={{ stopColor: "#c70700", stopOpacity: 1 }}
						offset="0"
					/>
					<stop
						style={{ stopColor: "#c70700", stopOpacity: 0 }}
						offset="1"
					/>
				</linearGradient>
				<linearGradient
					xlinkHref="#linearGradient5453"
					id="linearGradient5455"
					x1="102.99294"
					y1="104.24699"
					x2="31.650038"
					y2="104.24699"
					gradientUnits="userSpaceOnUse"
				/>
			</defs>
			<g transform="translate(-20.298423,-67.030034)">
				{/* Full Star Path */}
				<path
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					transform="matrix(1,0,0,-1,39.904605,243.36485)"
				/>
				{/* Half-Filled Gradient Path */}
				<path
					d="m 56.195501,188.89493 c 0.364653,0.0757 0.7273,0.11538 1.088301,0.11538 11.466114,0 24.869385,-39.31354 34.145666,-46.05316 9.276282,-6.73961 50.807212,-7.33845 54.350432,-18.24337 3.54323,-10.90492 -29.70398,-35.801215 -33.2472,-46.706137 -3.54323,-10.904922 8.72149,-50.587695 -0.55479,-57.327307 -9.27628,-6.739612 -43.227994,17.186981 -54.694108,17.186982 -0.342352,0 -0.707114,-0.02425 -1.088301,-0.06546 z"
					style={{
						fill: "url(#linearGradient5455)",
						stroke: "none",
						strokeWidth: 6.00009,
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					transform="matrix(-0.63153135,0,0,-0.63153135,133.3649,206.4862)"
				/>
			</g>
		</svg>
	) : color === "#2cd40f" ? (
		<svg
			width={width}
			height={height}
			viewBox="0 0 153.77994 147.24228"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
				<linearGradient id="linearGradient5453">
					<stop
						style={{ stopColor: "#2cd40f", stopOpacity: 1 }}
						offset="0"
					/>
					<stop
						style={{ stopColor: "#2cd40f", stopOpacity: 0 }}
						offset="1"
					/>
				</linearGradient>
				<linearGradient
					xlinkHref="#linearGradient5453"
					id="linearGradient5455"
					x1="102.99294"
					y1="104.24699"
					x2="31.650038"
					y2="104.24699"
					gradientUnits="userSpaceOnUse"
				/>
			</defs>
			<g transform="translate(-20.298423,-67.030034)">
				{/* Full Star Path */}
				<path
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					transform="matrix(1,0,0,-1,39.904605,243.36485)"
				/>
				{/* Half-Filled Gradient Path */}
				<path
					d="m 56.195501,188.89493 c 0.364653,0.0757 0.7273,0.11538 1.088301,0.11538 11.466114,0 24.869385,-39.31354 34.145666,-46.05316 9.276282,-6.73961 50.807212,-7.33845 54.350432,-18.24337 3.54323,-10.90492 -29.70398,-35.801215 -33.2472,-46.706137 -3.54323,-10.904922 8.72149,-50.587695 -0.55479,-57.327307 -9.27628,-6.739612 -43.227994,17.186981 -54.694108,17.186982 -0.342352,0 -0.707114,-0.02425 -1.088301,-0.06546 z"
					style={{
						fill: "url(#linearGradient5455)",
						stroke: "none",
						strokeWidth: 6.00009,
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					transform="matrix(-0.63153135,0,0,-0.63153135,133.3649,206.4862)"
				/>
			</g>
		</svg>
	) : (
		<svg
			width={width}
			height={height}
			viewBox="0 0 153.77994 147.24228"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
				<linearGradient id="linearGradient5453">
					<stop
						style={{ stopColor: "#ffcc00", stopOpacity: 1 }}
						offset="0"
					/>
					<stop
						style={{ stopColor: "#ffcc00", stopOpacity: 0 }}
						offset="1"
					/>
				</linearGradient>
				<linearGradient
					xlinkHref="#linearGradient5453"
					id="linearGradient5455"
					x1="102.99294"
					y1="104.24699"
					x2="31.650038"
					y2="104.24699"
					gradientUnits="userSpaceOnUse"
				/>
			</defs>
			<g transform="translate(-20.298423,-67.030034)">
				{/* Full Star Path */}
				<path
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					transform="matrix(1,0,0,-1,39.904605,243.36485)"
				/>
				{/* Half-Filled Gradient Path */}
				<path
					d="m 56.195501,188.89493 c 0.364653,0.0757 0.7273,0.11538 1.088301,0.11538 11.466114,0 24.869385,-39.31354 34.145666,-46.05316 9.276282,-6.73961 50.807212,-7.33845 54.350432,-18.24337 3.54323,-10.90492 -29.70398,-35.801215 -33.2472,-46.706137 -3.54323,-10.904922 8.72149,-50.587695 -0.55479,-57.327307 -9.27628,-6.739612 -43.227994,17.186981 -54.694108,17.186982 -0.342352,0 -0.707114,-0.02425 -1.088301,-0.06546 z"
					style={{
						fill: "url(#linearGradient5455)",
						stroke: "none",
						strokeWidth: 6.00009,
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					transform="matrix(-0.63153135,0,0,-0.63153135,133.3649,206.4862)"
				/>
			</g>
		</svg>
	);

export default StarHalf;
