import React, { useState } from "react";
import defaultAvatar from "../../assets/images/DefaultAvatar.webp";
import { useHistory } from "react-router-dom";
import "./style.scss";

/**
 * Component for displaying a user's avatar and username.
 * Provides interactive features such as showing a hover card with user details and navigation to the user's profile page.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.user - The user object containing user details.
 * @param {Function} props.setCardPosition - Function to set the position of the hover card.
 * @param {string} props.showCard - The ID of the user whose hover card is currently shown.
 * @param {Function} props.setShowCard - Function to set the visibility of the hover card.
 * @param {Function} props.setHoverCardUser - Function to set the user details for the hover card.
 *
 * @returns {JSX.Element} A link element that displays the user's avatar and username.
 * The link provides hover and click interactions for displaying more user information and navigating to the user's profile.
 */

const UsernameAvatarDisplay = ({
	user,
	setCardPosition,
	showCard,
	setShowCard,
	setHoverCardUser,
}) => {
	const [active, setActive] = useState(false);
	const history = useHistory();

	// clicking the username
	const handleAuthorClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// check if the user is on a desktop device
		if (
			(window.innerWidth > 750 && window.innerHeight > 550) ||
			(showCard === user?._id && active === true)
		) {
			return history.push(`/users/${user?._id}`);
		}

		// function to trigger when on mobile
		setCardPosition({ x: e.clientX, y: e.clientY });
		setActive(true);
		return setShowCard(user?._id);
	};

	// hovering the username
	const handleHover = (e, show) => {
		e.preventDefault();
		e.stopPropagation();

		// set the user of the hover card
		setHoverCardUser(user);

		// don't fire if on mobile
		if (window.innerWidth <= 550 || window.innerHeight <= 550) return;

		// show card
		if (show === true) {
			setCardPosition({ x: e.clientX, y: e.clientY });
			return setShowCard(user?._id);
		}

		// hide card
		if (show === false) {
			return setShowCard(null);
		}
	};

	return (
		<a
			href={`/users/${user?._id}`}
			onMouseEnter={(e) => handleHover(e, true)}
			onMouseLeave={(e) => handleHover(e, false)}
			onClick={(e) => handleAuthorClick(e)}
			className="username-avatar-display"
			style={{
				boxShadow: user?.icon_glow
					? `0 0 3px 1px ${user?.icon_glow}`
					: "none",
				borderColor: user?.icon_glow,
			}}>
			<span
				className="icon-avatar"
				style={{
					borderColor: user?.icon_outline,
					boxShadow: "unset",
				}}>
				<img
					alt="Author Avatar"
					className="icon-avatar__image"
					onError={(e) => {
						// @ts-ignore
						e.target.src = defaultAvatar;
					}}
					src={user?.avatar ? user?.avatar : defaultAvatar}
				/>
			</span>
			{user?.username}&nbsp;{" "}
		</a>
	);
};

export default UsernameAvatarDisplay;
