//! download files
const downloadFile = (blob, level) => {
	// Create a download link
	const a = document.createElement("a");
	a.style.display = "none";
	// Set the Blob as the data source
	a.href = URL.createObjectURL(blob);
	// assure filenames are shorter than 35 characters for MB64
	let levelName = level?.name;
	if (levelName?.length > 35 && level?.game === 4)
		levelName = levelName.substring(0, 35);
	// create a file name
	a.download = `${levelName}.mb64`;
	// Add the link to the DOM and trigger a click to start the download
	document.body.appendChild(a);
	a.click();
	// Remove the link and revoke the Blob URL when done
	document.body.removeChild(a);
	URL.revokeObjectURL(a.href);
};

export default downloadFile;
