import React, { useEffect, useState } from "react";
import FaqItems from "../../assets/jsons/FaQ.json";

// page styles
import "./style.scss";

// ------[Adding a new question]------ //
// Each question has a category ID and question ID, labelled as e.g. C2Q1 for the first question in category 2.
// These need to be applied to the following properties: id, data-bs-target, aria-controls & aria-labelledby

/**
 * The FAQ page of the site, going over and explaining several of its features/functionality.
 *
 * @returns The site's FAQ page.
 */
const FAQ = (props) => {
	useEffect(() => {
		document.title = "FAQ - Level Share Square";
	}, []);

	return (
		<div className="container mt-4">
			<div className="col-12 col-lg-9 mx-auto">
				{/* Top card, containing main info for the FAQ page */}
				<div className="card">
					<div className="card-header">
						<img
							src={props.images.Exclamation}
							alt="Exclamation"
							width="48"
							height="48"
						/>
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Frequently Asked Questions
						</span>
					</div>
					<div className="card-body">Welcome to the FAQ page!</div>
				</div>
				<br />

				{/* Level-related questions & answers */}
				{FaqItems.map((item, index) => {
					return (
						<span key={index}>
							<div className="card">
								<div className="card-header">
									<span
										className="align-middle"
										style={{
											paddingLeft: "7px",
											fontSize: "26px",
										}}>
										{item?.header}
									</span>
								</div>
								<div className="card-body">
									{item?.content?.questions?.map(
										(content, key) => (
											<span key={key}>
												<QuestionAndAnswer
													question={content}
													answer={
														item?.content?.answers[
															key
														]
													}
												/>
											</span>
										),
									)}
								</div>
							</div>
							<br />
						</span>
					);
				})}
			</div>
		</div>
	);
};

const QuestionAndAnswer = ({ question, answer }) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const [fade, setFade] = useState<number>(0);
	const [mouseOver, setMouseOver] = useState<boolean>(false);

	const handleClick = (e) => {
		e.preventDefault();
		setIsActive((prevState) => !prevState);
	};

	// fadeout
	useEffect(() => {
		if (isActive && fade < 20)
			setTimeout(() => {
				setFade((prevState) => prevState + 1);
			}, 10);
		if (!isActive && fade > 0) {
			setFade(1);
			setTimeout(() => {
				setFade(0);
			}, 750);
		}
	}, [isActive, fade]);

	return (
		<>
			<a
				href="#!"
				onClick={(e) => handleClick(e)}
				onMouseEnter={() => setMouseOver(true)}
				onMouseLeave={() => setMouseOver(false)}>
				<h2
					style={{
						color: mouseOver ? "#a0f3e1" : "#32c7d1",
						transition: "color 0.65s ease-in-out",
					}}>
					{question}
				</h2>
			</a>
			{!isActive && fade === 0 ? null : (
				<div
					style={{
						opacity: fade / 20,
						transition: "opacity 0.75s ease-in-out",
					}}
					dangerouslySetInnerHTML={{ __html: answer }}
				/>
			)}
			<br />
		</>
	);
};

export default FAQ;
