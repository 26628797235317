// local storage
export const LocalStorageConstants = {
	// sessions
	Sessions: {
		LOGIN_NAME: "LSS_login_session",
	},
	// staff
	Staff: {
		TOKEN_NAME: "staffToken",
	},
	// toast messages
	Toasts: {
		MESSAGE: "toastMessage",
		ERROR: "toastError",
	},
};

// users
export const UserConstants = {
	// specifically staff roles
	StaffRoles: ["Admin", "Moderator", "Mitten", "Partner"],
	ModRoles: ["Admin", "Moderator", "Partner"],
	// main roles
	MainRoles: {
		LEADER: "Leader",
		LEADER_LOWER: "leader",
		ADMIN: "Admin",
		ADMIN_LOWER: "admin",
		MODERATOR: "Moderator",
		MODERATOR_LOWER: "moderator",
		MITTEN: "Mitten",
		MITTEN_LOWER: "mitten",
		PARTNER: "Partner",
		PARTNER_LOWER: "partner",
		DEVELOPER: "LSS Developer",
		DEVELOPER_LOWER: "developer",
		DONATOR: "Donator",
		DONATOR_PLUS: "Donator+",
		MEMBER: "Member",
		UNVERIFIED: "Unverified User",
		BETA_TESTER: "Beta tester",
		ELDER_GOD: "Elder God",
		ARCHIVE_ACCOUNT: "Archive account",
	},
	// subroles
	SubRoles: {
		REVIEWER: "Reviewer",
		SCREENSAVER: "Screensaver",
		THEME_BREWER: "Theme brewer",
		HELPSQUAD: "Helpsquad",
		STARGAZER: "Stargazer",
	},
	// punishments
	Punishments: {
		BANNED: "Banned",
		DEACTIVATED: "Deactivated account",
		MUTED: "Muted",
		RATE_RESTRICTED: "Rate Restricted",
		WARNING: "Warning",
	},
};

// levels
export const LevelConstants = {
	// statuses
	Statuses: {
		FEATURED: "Featured",
		PUBLIC: "Public",
		PRIVATE: "Private",
		DELETED: "Deleted",
	},
	// level access / share states
	ShareStates: {
		PUBLIC: "Public",
		PRIVATE: "Private",
		LINK: "Link",
		FRIENDS: "Friends",
		INVITE: "Invite",
	},
	// difficulties
	Difficulties: {
		EASY: "Easy",
		MEDIUM: "Medium",
		HARD: "Hard",
		EXTREME: "Extreme",
	},
};

// common response messages
export const ResponseMessages = {
	// error responses
	Error: {
		GENERIC_500: "Something went wrong, please try again.",
	},
};

// BBCode
export const AllowedBBCodeTags = {
	// global
	BBCODE_GLOBAL_TAGS: [
		"i",
		"b",
		"u",
		"s",
		"color",
		"colour",
		"spoiler",
		"tooltip",
	],
	BBCODE_EXTENDED_TAGS: [
		"i",
		"b",
		"u",
		"s",
		"color",
		"colour",
		"spoiler",
		"h1",
		"h2",
		"h3",
		"vibrant",
		"tooltip",
	],
	BBCODE_BONUS_TAGS: ["rainbow"]
};

// colors
export const Colors = {
	// individual
	RED: "hydrawisp-red",
	ORANGE: "gloamvitiation-orange",
	GREEN: "wildheart-green",
	GRAY: "gray",
	YELLOW: "yellow",
	BLACK: "black",
	// array
	Array: {
		R_O_G: ["wildheart-green", "gloamvitiation-orange", "hydrawisp-red"],
	},
};

export const Time = {
	MMM_DD_YYYY_TIME: "MMM D, YYYY - h:mm A",
	MMM_DD_TIME: "MMM D, h:mm A",
	MMM_DD: "MMM D",
};

export const PatternDefaultSizes: { value: string; size: string }[] = [
	{ value: "default", size: "65" },
	{ value: "strong", size: "65" },
	{ value: "stripes", size: "65" },
	{ value: "cosmic", size: "80" },
	{ value: "berry", size: "65" },
	{ value: "spooky", size: "100" },
	{ value: "fishy", size: "100" },
	{ value: "pipeline", size: "70" },
];