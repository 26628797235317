import axios from "axios";
import { toast } from "react-toastify";
import mitt from "mitt";

// constants
import { LocalStorageConstants } from "../constants";

// Check if the app is running on localhost or production
const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

// Dynamically set the base URL based on the environment
export const api = axios.create({
	baseURL: isLocal ? "http://localhost:5000/api" : `${window.location.origin}/api`, // Localhost or production
});

//! set up token for auth middleware
api.interceptors.request.use((req) => {
	const sessionToken = localStorage.getItem(
		LocalStorageConstants.Sessions.LOGIN_NAME,
	);
	const staffToken = localStorage.getItem(
		LocalStorageConstants.Staff.TOKEN_NAME,
	);

	// create bearer token
	if (sessionToken)
		req.headers.authorization = `Bearer ${sessionToken || ""} ${staffToken || ""
			}`.trim();

	return req;
});

// define an emitter for user ran states
export const rankEmitter = mitt();

//! interceptor for error
const errorHandler = (error) => {
	// handle an error
	if (error.response.status === 440) {
		// return response
		if (error.response.config.url === "/users/get_id_from_token")
			throw error;
		// logout if session expired
		if (error.response.data.responseMessage)
			toast.error(error.response.data.responseMessage);
		// clear session
		setTimeout(() => {
			localStorage.removeItem(LocalStorageConstants.Sessions.LOGIN_NAME);
			window.location.reload();
			sessionStorage.clear();
		}, 4000);
		throw error;
	}
	if (error.response.status === 429) {
		toast.error(error.response.data);
		throw error;
	}
	if (error.response.data.responseMessage)
		toast.error(error.response.data.responseMessage);

	// Re-throw the error to propagate it to the caller
	throw error;
};

//! interceptor for response
const responseHandler = (response) => {
	// used for updating rank states globally
	if (response?.data?.rankResult?.rankCalc === true)
		rankEmitter.emit("response", response?.data?.rankResult);
	// on click for toast messages
	if (response.data.onClick !== undefined && response.data.responseMessage) {
		toast.success(response.data.responseMessage, {
			onClick: () => {
				window.location.href = response.data.onClick;
			},
		});
		return response;
	}

	if (response.data.responseMessage)
		toast.success(response.data.responseMessage);
	return response;
};

//* Set up response interceptor to catch errors for all requests
api.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error),
);

// include all api routes
export * from "./account";
export * from "./announcements";
export * from "./app";
export * from "./dashboard";
export * from "./events";
export * from "./games";
export * from "./hints";
export * from "./levelComments";
export * from "./levels";
export * from "./notifications";
export * from "./profile";
export * from "./reports";
export * from "./users";
