import React, { useEffect, useState } from "react";
import StarEmpty from "../../assets/vectors/StarEmpty";
import StarFull from "../../assets/vectors/StarFull";
import StarHalf from "../../assets/vectors/StarHalf";

// Map the 5 stars, either empty or full
const MapStars = ({ level, rate, width, height, top, color, starCount }) => {
	const [starRating, setStarRating] = useState<number[]>([]);

	// Decide whether to use the level or the rate
	useEffect(() => {
		if (level?.rating || rate) {
			let ratingArray: number[] = [];
			const rating = rate ? parseFloat(rate) : parseFloat(level?.rating);
			// loop over each star possibility
			for (let i = 1; i <= starCount; i++) {
				let result = rating - i;
				if (result >= 0) {
					// append a full star
					ratingArray.push(2);
					continue;
				}
				if (0 > result && result > -1) {
					// if the number behind the comma is smaller or the same as 0.5, apply half a star, otherwise a full star
					ratingArray.push(result + 1 <= 0.5 ? 1 : 2);
					continue;
				}
				// fallback is to add empty star
				ratingArray.push(0);
				continue;
			}
			setStarRating(ratingArray);
		}
	}, [level?.rating, rate, starCount]);

	// If none are present, render nothing
	if (!starRating?.length) return null;

	return (
		<div
			className="unselectable flex-stars"
			style={{
				top: top,
			}}>
			{starRating?.map((value, key) => (
				<span key={key}>
					{value === 2 ? ( // 2 is full star
						<StarFull width={width} height={height} color={color} />
					) : value === 1 ? ( // 1 is half star
						<StarHalf width={width} height={height} color={color} />
					) : (
						value === 0 && ( // 0 is empty star
							<StarEmpty width={width} height={height} />
						)
					)}
				</span>
			))}
		</div>
	);
};

export default MapStars;
