import presetHTML5 from "@bbob/preset-html5";

const tagAttr = (style) => {
	return {
		attrs: {
			style,
			className: style?.className || "",
			onClick: style?.onClick || null,
			message: style?.dataMessage || null,
		},
	};
};
const BBCodeParser = presetHTML5.extend((tags) => {
	return {
		...tags,
		b: (...args) => ({
			...tags.b(...args),
			...tagAttr({
				fontWeight: "bold",
			}),
		}),
		i: (...args) => ({
			...tags.i(...args),
			...tagAttr({
				fontStyle: "italic",
			}),
		}),
		u: (...args) => ({
			...tags.u(...args),
			...tagAttr({
				textDecoration: "underline",
			}),
		}),
		s: (...args) => ({
			...tags.s(...args),
			...tagAttr({
				textDecoration: "line-through",
			}),
		}),
		color: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				// @ts-ignore
				color: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		colour: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				// @ts-ignore
				color: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		tooltip: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				className: "BBCode-tooltip",
				backgroundColor: "black",
				color: "white",
				// @ts-ignore
				dataMessage: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		spoiler: (attrs, ...children) => ({
			...tags.style(attrs, ...children),
			...tagAttr({
				borderRadius: "4px",
				cursor: "pointer",
				className: "unselectable spoiler",
				onClick: (e) => {
					let element = e.target;

					// Traverse up the DOM tree until we find a parent with the desired class
					while (
						element &&
						element?.parentElement?.classList?.contains("spoiler")
					) {
						element = element.parentElement;
					}
					const spoilerActive = element?.classList?.contains("spoiler-active");
					const childElements = element.querySelectorAll("*"); // Select all descendants

					if (spoilerActive) {
						element.classList.remove("spoiler-active");
						element.classList.add("spoiler");
						childElements.forEach((child) => {
							child.classList.add("spoiler");
							child.classList.remove("spoiler-active");
						});
					} else {
						element.classList.add("spoiler-active");
						element.classList.remove("spoiler");
						childElements.forEach((child) => {
							child.classList.add("spoiler-active");
							child.classList.remove("spoiler");
						});
					}

				},
			}),
		}),
		h1: (...args) => ({
			...tags.style(...args),
			...tagAttr({
				fontSize: "2em",
			}),
		}),
		h2: (...args) => ({
			...tags.style(...args),
			...tagAttr({
				fontSize: "1.5em",
			}),
		}),
		h3: (...args) => ({
			...tags.style(...args),
			...tagAttr({
				fontSize: "1.2em",
			}),
		}),
		vibrant: (attrs, ...children) => ({
			...tags.style(attrs, ...children),
			...tagAttr({
				className: "vibrant",
			}),
		}), rainbow: (attrs, ...children) => ({
			...tags.style(attrs, ...children),
			...tagAttr({
				className: "item-text",
			}),
		})
	};
});

export default BBCodeParser;
