// imports
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

/**
 * The SMC game with its buttons.
 *
 * @param {*} props
 * @returns
 */
const SMCGame = () => {
	const [leavePrevention, setLeavePrevention] = useState<boolean>(false);
	const [resourceModalShow, setResourceModalShow] = useState<boolean>(false);
	const gameSourceURL = "./../../html5/supermarioconstruct";
	const history = useHistory();

	// leave prevention button
	const handlePreventionChange = () => {
		setLeavePrevention((prevState) => !prevState);
		//exclamation for readability and use the new state
		!leavePrevention
			? toast.success(
					"You will now get a confirmation before closing the page.",
				)
			: toast.info("Turned off page leave prevention.");
	};

	useEffect(() => {
		// Spacebar + arrow key codes
		const preventKeydown = (e) => {
			if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
				e.preventDefault();
			}
		};
		window.addEventListener("keydown", preventKeydown, false);
		// cleanup
		return () => {
			window.removeEventListener("keydown", preventKeydown);
		};
	});

	// upon render
	useEffect(() => {
		// function for leave prevention
		const preventUnload = (e) => {
			if (leavePrevention) {
				const confirmationMessage =
					"Are you sure you want to leave this page? Any unsaved changes might be lost. ";
				e.returnValue = confirmationMessage; // Standard
				return confirmationMessage;
			}
		};

		const unblock = history.block(() => {
			// This block will be called when the user tries to leave the page within your SPA
			if (leavePrevention) {
				return "Are you sure you want to leave this page? Any unsaved changes might be lost.";
			}
		});

		// add and remove event listeners
		if (leavePrevention)
			window.addEventListener("beforeunload", preventUnload);
		if (!leavePrevention)
			window.removeEventListener("beforeunload", preventUnload);

		// cleanup
		return () => {
			unblock(); // Unregister the block when the component unmounts
			window.removeEventListener("beforeunload", preventUnload);
		};
	}, [leavePrevention, history]);

	useEffect(() => {
		document.title = "Super Mario Construct - Level Share Square";

		// target iframe
		const iframe = document.querySelector(
			".game-container",
		) as HTMLIFrameElement;

		if (iframe) {
			// prevent scroll when focussed
			iframe.focus({ preventScroll: true });
		}
	});

	const [mobileControls, setMobileControls] = useState<boolean>(false);

	useEffect(() => {
		if (!mobileControls) return;
		// Dynamically load the smcmobile.user.js script
		const script = document.createElement("script");
		script.src = "/scripts/smcmobile.user.js"; // Replace with the correct relative path
		script.async = true;
		// Called when the script is loaded successfully.
		script.onerror = (err) =>
			console.error("Failed to load controls:", err);
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
			script.remove();
			const gamepad = document.getElementById("touch-gamepad");
			if (!gamepad) return;
			while (gamepad.firstChild) {
				gamepad.removeChild(gamepad.firstChild);
			}
			gamepad.remove();
			const debug = document.getElementById("debug-info");
			if (debug) {
				debug.remove();
			}
		};
	}, [mobileControls]);

	// modal with the resources
	const resourceModal = () => (
		<Modal
			show={resourceModalShow}
			onHide={() => setResourceModalShow(false)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="lg"
			className="popup-modal">
			<span className="modal-fill">
				<Modal.Body className="modal-bdy modal-filter">
					<h1 className="center-textoutput">Resources</h1>
					<hr />
					<div className="resource-buttons">
						<button
							className="btn btn-primary"
							onClick={() =>
								window.open(
									"https://levelsharesquare.itch.io/super-mario-construct",
									"_blank",
								)
							}>
							Desktop version
						</button>
						<button
							className="btn btn-primary__special"
							onClick={() =>
								window.open(
									"https://github.com/Level-Share-Square/SMC-released-sprites/releases",
									"_blank",
								)
							}>
							SMC Public sprite release
						</button>
						<button
							className="btn btn-primary__special"
							onClick={() =>
								window.open(
									"https://drive.google.com/drive/u/1/folders/17zTEYFXoF0J6aSdyMDdK1sqq8dLeqZ5Q",
									"_blank",
								)
							}>
							SMC Public music release
						</button>
						<button
							className="btn btn-primary"
							onClick={() =>
								window.open(
									"https://smcbgs.wordpress.com/",
									"_blank",
								)
							}>
							Custom background database
						</button>
						<button
							className="btn btn-primary"
							onClick={() =>
								window.open(
									"https://docs.google.com/spreadsheets/d/1rt13VWhMsVYL3HQqO1BuwxEjVSv4ywIaqaiPrWg0hdo/edit?gid=1775439400#gid=1775439400",
									"_blank",
								)
							}>
							Custom music database
						</button>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-buttons modal-filter">
					<Button
						className="btn-primary__danger"
						onClick={() => setResourceModalShow(false)}>
						Close
					</Button>
				</Modal.Footer>
			</span>
		</Modal>
	);

	const [fullscreen, setFullscreen] = useState(false);

	// change game wrapper style when fullscreen
	useEffect(() => {
		const handleFullscreenChange = () => {
			const fullscreenElement = document.fullscreenElement;
			// if game wrapper is in fullscreen
			if (fullscreenElement && fullscreenElement.id === "game-wrapper") {
				return setFullscreen(true);
			}
			// if not in fullscreen
			return setFullscreen(false);
		};
		// add event listener
		document.addEventListener("fullscreenchange", handleFullscreenChange);
		// cleanup
		return () => {
			document.removeEventListener(
				"fullscreenchange",
				handleFullscreenChange,
			);
		};
	});

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12">
					<div
						className="card"
						style={{
							textAlign: "center",
							width: "fit-content",
							margin: "0 auto",
						}}>
						<div
							className="card-body"
							id="gameCard"
							style={{ borderRadius: "inherit" }}>
							<h2 className="card-title">
								Super Mario Construct
							</h2>
							<div
								id="game-wrapper"
								className={
									mobileControls && fullscreen
										? "mobile-wrapper"
										: ""
								}>
								<iframe
									title="Super Mario Construct"
									src={gameSourceURL}
									id="game"
									className={`game-container`}
									allowFullScreen={true}></iframe>
							</div>
						</div>
					</div>
					<br />

					<div className="card" style={{ textAlign: "center" }}>
						<div
							className="card-body"
							id="gameButtons"
							style={{ borderRadius: "inherit" }}>
							<button
								type="button"
								title="Get a warning when you close the page to prevent you from losing progress when making levels."
								onClick={() => handlePreventionChange()}
								className={`btn btn-primary__${
									leavePrevention ? "special" : "danger"
								} btn-lg m-2`}>
								Level making mode&nbsp;
								{leavePrevention ? (
									<span
										className="material-icons"
										style={{
											position: "relative",
											top: "5px",
										}}>
										check
									</span>
								) : null}
							</button>
							<button
								type="button"
								title="Toggle mobile controls on the game."
								onClick={() => {
									const isMobileDevice =
										/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
											navigator.userAgent,
										);
									if (!isMobileDevice) {
										toast.error(
											"You can only do this on a mobile device.",
										);
										return;
									}
									setMobileControls((prev) => !prev);
								}}
								className={`btn btn-primary__${
									mobileControls ? "special" : "danger"
								} btn-lg m-2`}>
								Mobile controls&nbsp;
								{mobileControls ? (
									<span
										className="material-icons"
										style={{
											position: "relative",
											top: "5px",
										}}>
										check
									</span>
								) : null}
							</button>
							<a
								href={gameSourceURL}
								target="_blank"
								rel="noreferrer noopener">
								<button
									type="button"
									className="btn btn-primary btn-lg m-2">
									Fullscreen page
								</button>
							</a>
							<button
								type="button"
								onClick={() => setResourceModalShow(true)}
								className="btn btn-primary btn-lg m-2">
								Resources
							</button>
							<a
								href="https://discord.gg/wFKP6pcK4p"
								target="_blank"
								rel="noreferrer noopener">
								<button
									type="button"
									className="btn btn-discord btn-lg m-2">
									Community Discord
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
			{resourceModal()}
		</div>
	);
};

export default SMCGame;
