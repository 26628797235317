import React, { useEffect, useRef, useState } from "react";
import BBCode from "@bbob/react/es/Component";
import BBCodeParser from "../../functions/applyBBCode";
import Linkify from "react-linkify";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";
import LinkRenderer from "./LinkRenderer";
import { AllowedBBCodeTags } from "../../constants";

const ReadMore = (props) => {
	const document = props?.document;
	const content = props?.content || props?.description;
	const maxHeight = props?.height || 300;
	const extendBBCode = props?.extendBBCode || false;
	const bonusBBCode = props?.bonusBBCode || false;
	const animationTime = props?.animationTime || 1200;
	const authorRank: number | string = props?.rank || 0;
	const authorRankNum: number =
		typeof authorRank === "string" ? parseInt(authorRank, 10) : authorRank;
	const [limitHeight, setLimitHeight] = useState<boolean | null>(null);
	const [disabled, setDisabled] = useState<boolean>(false);
	const elementRef = useRef<any>(null);
	const [initialHeight, setInitialHeight] = useState<number>(999999);
	const [hasEmbed, setHasEmbed] = useState<boolean>(false);
	const [mask, setMask] = useState<boolean>(false);

	useEffect(() => {
		// Access the current property of the ref to get the DOM node
		const element = elementRef.current;
		// Check if the ref is assigned to an element
		if (element) {
			// Get the height of the element
			const height = element?.clientHeight;
			setInitialHeight(height);
			if (height - 50 > maxHeight) {
				setLimitHeight(true);
				setMask(true);
			} else setLimitHeight(null);

			if (hasEmbed) setHasEmbed(false);
		}
	}, [maxHeight, hasEmbed]);

	const handleClick = (e) => {
		e.preventDefault();
		// invert state
		setLimitHeight((prev) => !prev);
		if (!mask) setMask(true);
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
			if (mask) setMask(false);
		}, animationTime);
	};
	return (
		<div id={document?._id}>
			<div
				ref={elementRef}
				className={mask ? "read-more-mask" : ""}
				style={{
					transition: `max-height ${animationTime}ms ease-in-out`,
					maxHeight: limitHeight
						? maxHeight + "px"
						: initialHeight + "px", // force smooth animation using px
					overflow: "hidden",
					width: "100%",
					display: "inline-block", // properly render "edited",
				}}>
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<LinkRenderer
							decoratedHref={decoratedHref}
							decoratedText={decoratedText}
							key={key}
							setHasEmbed={setHasEmbed}
							rank={authorRank}
						/>
					)}>
					<BBCode
						plugins={[BBCodeParser()]}
						options={{
							onlyAllowTags:
								extendBBCode || authorRankNum >= 6
									? [
											...AllowedBBCodeTags.BBCODE_EXTENDED_TAGS,
											...(bonusBBCode
												? AllowedBBCodeTags.BBCODE_BONUS_TAGS
												: []),
										]
									: AllowedBBCodeTags.BBCODE_GLOBAL_TAGS,
						}}>
						{removeExcessiveBreaks(content)}
					</BBCode>
				</Linkify>
				{document?.edited && (
					<span
						className="gray"
						style={{
							fontSize: "11px",
						}}>
						&nbsp;&nbsp;(Edited)
					</span>
				)}
			</div>

			{limitHeight !== null && (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}>
					<br />
					<button
						className="btn-primary"
						disabled={disabled}
						style={{
							borderRadius: "14px",
							padding: "2px 8px 2px 8px ",
						}}
						onClick={(e) => handleClick(e)}>
						{limitHeight ? "Read more..." : "Collapse"}
					</button>
				</div>
			)}
		</div>
	);
};

export default ReadMore;
