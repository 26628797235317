import React, { useEffect, useState } from "react";
import { getEvents } from "../../api";
import { useLocation, useHistory } from "react-router-dom";
import DefaultThumbnail from "../../assets/images/DefaultThumbnail.png";
import "./style.scss";

// constants
import moment from "moment";
import { mapHighestRole } from "../../functions/styleAppliers";
import { Spinner } from "react-bootstrap";
import { Link } from "@mui/material";
import { Colors } from "../../constants";

const Events = () => {
	const [eventsChanged, setEventsChanged] = useState<boolean>(true);
	const [isQuerying, setIsQuerying] = useState<boolean>(false);
	const [events, setEvents] = useState<any>([]);

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};
	const query: URLSearchParams = useQuery();
	const page: number = parseInt(query.get("page") || "1");

	useEffect(() => {
		document.title = "Contests & Events - Level Share Square";
	}, []);

	useEffect(() => {
		// stop if querying
		if (isQuerying) return setEventsChanged(false);

		if (eventsChanged) {
			setIsQuerying(true);
			// fetch
			getEvents(page)
				.then((res) => {
					// update documents
					setEvents(res?.data?.events);
				})
				.finally(() =>
					// prepare next cycle
					setIsQuerying(false),
				);
		}
	}, [eventsChanged, isQuerying, page]);

	// no events found
	if (!events?.length)
		return (
			<div className="container mt-4">
				<div className="jumbotron d-flex justify-content-center">
					<h1>Contests & Events</h1>
				</div>
				<br />
				{!eventsChanged && !isQuerying ? (
					<div className="card card-body center-textoutput">
						<h2>No events found.</h2>
					</div>
				) : (
					<div className="d-flex justify-content-center">
						<Spinner animation="grow" variant="primary" />
					</div>
				)}
			</div>
		);

	// render events page
	return (
		<div className="container mt-4">
			<div className="jumbotron d-flex justify-content-center">
				<h1>Contests & Events</h1>
			</div>
			<br />
			<div className="events-grid">
				{events?.map((event) => (
					<React.Fragment key={event?._id}>
						<MapEvents event={event} />
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

const MapEvents = (props) => {
	const history = useHistory();
	const event = props?.event;

	const active: boolean = event?.active;
	const submissions: number =
		event?.submissionType === "poll"
			? event?.pollVotes || 0
			: event?.submissionCount || 0;
	const startDate: Date = new Date(event?.startDate);
	const endDate: Date = new Date(event?.endDate);
	const appendHours: string = active ? " - h:mm A" : "";
	const primaryHost: any = event?.hosts?.[0];
	const authorRoles: string[] = primaryHost?.mainRoles || [];
	const roleColor: string = mapHighestRole(authorRoles, "color");
	const thumbnail: string = event?.thumbnail || DefaultThumbnail;
	const type: string = event?.type || "Contest";
	const hrefValue: string = `/events/${event?._id}`;
	const oneHourAfterStart: Date = new Date(startDate.getTime() + 3600 * 1000);
	const afterStartDate: boolean = oneHourAfterStart <= new Date();

	// status
	const eventStatusId = active
		? 1
		: !afterStartDate
			? 0
			: event?.hasWinners
				? 3
				: 2;
	const eventStatuses = ["Unstarted", "Active", "Results pending", "Ended"];
	const eventStatus = eventStatuses[eventStatusId] || "Unknown";

	return (
		<div
			className="card event-card"
			style={{ overflow: "hidden", border: "unset" }}>
			<Link
				onClick={(e) => {
					// let it open in new tab
					if (e.ctrlKey) return;
					e.preventDefault();
					history.push(hrefValue);
					window.scrollTo(0, 0);
				}}
				href={hrefValue}>
				<button
					className="btn btn-primary"
					style={{
						borderRadius: "0px",
						fontStyle: "bold",
						width: "100%",
					}}>
					{event?.name}
				</button>
				<div
					className="card-body event-body"
					style={{
						backgroundImage: `url("${thumbnail}")`,
					}}>
					<div className="event-text unselectable">
						<b
							className={`event-text__item ${Colors.Array.R_O_G[eventStatusId - 1]}`}>
							{eventStatus}
						</b>
						<span className="event-text__item">
							Start:{" "}
							<span className="blue">
								{moment(startDate).format(
									`MMM D, YYYY${appendHours}`,
								)}
							</span>
						</span>
						<span className="event-text__item">
							End:{" "}
							<span className="blue">
								{moment(endDate).format(
									`MMM D, YYYY${appendHours}`,
								)}
							</span>
						</span>
						<span className="event-text__item">
							{submissions}{" "}
							{event?.submissionType === "poll"
								? "vote"
								: "submission"}
							{submissions === 1 ? "" : "s"}
						</span>
						<span
							className="event-text__item yellow"
							style={{ marginTop: "10px" }}>
							{type} hosted by
						</span>
						<span className="event-text__item">
							<span
								className="main-roles"
								style={{
									color: roleColor,
									borderColor: roleColor,
									boxShadow: `0 0 1px 1px ${roleColor}`,
									position: "relative",
									padding: "0 4px",
								}}>
								{mapHighestRole(authorRoles, "role")}
							</span>{" "}
							{primaryHost?.username}
						</span>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default Events;
